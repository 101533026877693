import React from 'react'
import { Root } from 'templates/layout'
import { EmailLink, PhoneLink } from 'components/design'
import { Rimg } from 'components/images'
import CuubWordmark from 'components/cuub-wordmark'
import SocialLinks from 'embeds/social-links'
import ContactForm from 'forms/contact'

const ContactsPageTpl = ({
	emails,
	heading,
	hoomans,
	main_subheading,
	main_email,
	country_specific_phones,
	phone_formatted,
	social_links,
}) => (
	<Root
		desktopMenuColor="text-black"
		desktopBgClass="bg-transparent"
		desktopWordmarkPosition="static"
		desktopCtaVariant="hidden"
		hideFooter
		mainFullWidth
	>
		<section className="relative lg:flex lg:h-screen lg:flex-col lg:justify-center lg:pt-20 lg:pb-screen-step-3/2">
			<div className="-z-1 pointer-events-none absolute right-0 top-0 hidden h-screen w-1/2 bg-black/5 lg:block" />
			<div id="contacts-container" className="flex flex-col lg:flex-row">
				<div className="mt-24 px-screen-step lg:mt-0 lg:h-full lg:w-1/2 lg:pr-screen-step-3/2">
					<h1>{heading}</h1>

					<h5 id="main-subheading" className="mt-10 leading-none opacity-25">
						{main_subheading}
					</h5>

					<div className="mt-6 flex flex-col justify-between gap-y-5 font-headings text-2xl font-semibold leading-none lg:flex-row lg:gap-y-0">
						<div className="lg:w-[calc(7.5*var(--screen-grid-step))]">
							<PhoneLink
								number={phone_formatted}
								countrySpecific={country_specific_phones}
							/>
						</div>
						<div className="lg:w-[calc(7.5*var(--screen-grid-step))]">
							<EmailLink email={main_email} />
						</div>
					</div>

					<div
						id="emails"
						className="mt-10 flex flex-col justify-between gap-y-5 border-t border-b border-black/20 py-8 lg:flex-row lg:gap-y-0"
					>
						{emails.slice(0, 2).map(({ address, label }) => (
							<div
								className="lg:w-[calc(7.5*var(--screen-grid-step))]"
								key={address}
							>
								<p className="leading-tight opacity-25">{label}</p>
								<p className="mt-0.5 font-semibold">
									<EmailLink email={address} />
								</p>
							</div>
						))}
					</div>

					<div
						id="hoomans"
						className="mt-8 flex flex-col justify-between gap-y-6 pb-8 lg:flex-row lg:gap-y-0 lg:pb-0"
					>
						{hoomans.map(
							({
								email,
								linkedin,
								name,
								position,
								square_serious_photo,
							}) => (
								<div
									className="flex gap-x-4 lg:w-[calc(7.5*var(--screen-grid-step))] lg:flex-col lg:gap-x-0"
									key={name}
								>
									<Rimg
										image={square_serious_photo}
										position={4}
										mobilePosition={6}
										className="aspect-square w-screen-step-6 flex-none lg:w-screen-step-4"
									/>
									<div>
										<p className="mt-4">{name}</p>
										<p className="opacity-25">{position}</p>
										<p className="mt-4 font-semibold leading-none">
											<EmailLink email={email} />
										</p>
										<p className="mt-4 leading-none">
											<a
												href={linkedin}
												className="accent-hover group inline-block h-3.5 text-sm font-semibold leading-none"
												target="_blank"
												rel="noreferrer"
											>
												<i className="mr-4 inline-block h-3 w-3 bg-black/25 transition-colors duration-300 group-hover:bg-accent" />
												LinkedIn
											</a>
										</p>
									</div>
								</div>
							)
						)}
					</div>
				</div>
				<div className="bg-black/5 px-screen-step lg:h-full lg:w-1/2 lg:bg-transparent lg:pl-screen-step-3/2">
					{/* pt aligns h2 with h1 by baseline, equals the difference in line heights */}
					<div className="my-10 lg:my-0 lg:pt-[calc(3.5rem-1.2*2.5rem)]">
						<h2 className="mb-12 lg:mb-10">Reach out now</h2>

						<ContactForm />
					</div>
				</div>
			</div>

			<div className="pointer-events-none sticky bottom-0 flex w-full items-center justify-between bg-white px-screen-step py-screen-step lg:absolute lg:bg-transparent lg:pt-0">
				<div className="pointer-events-auto w-screen-step-4 lg:invisible lg:w-screen-step-3">
					<CuubWordmark />
				</div>
				<div className="pointer-events-auto w-screen-step-6 lg:w-screen-step-4">
					<SocialLinks links={social_links} />
				</div>
			</div>
		</section>
	</Root>
)

export default ContactsPageTpl
