import React from 'react'
import { graphql } from 'gatsby'
import ContactsPageTpl from 'templates/contacts/page'

function ContactsPage({
	data: {
		prismicContactsPage: { data },
	},
}) {
	const hoomans = data.hoomans.slice(0, 2).map(
		({
			hooman: {
				document: { data },
			},
		}) => data
	)
	hoomans.forEach((hooman) => {
		hooman.links.forEach(({ address, label }) => {
			if (label === 'Email') {
				hooman.email = address
			}
			if (label === 'LinkedIn') {
				hooman.linkedin = address
			}
		})
	})

	return <ContactsPageTpl {...data} hoomans={hoomans} />
}

export default ContactsPage

export const query = graphql`
	query ContactsPageQuery {
		prismicContactsPage {
			...ContactsFragment
			data {
				heading
				hoomans {
					hooman {
						document {
							... on PrismicTeamMember {
								id
								data {
									links {
										address
										label
									}
									name
									position
									square_serious_photo {
										dimensions {
											height
											width
										}
										url
									}
								}
							}
						}
					}
				}
			}
		}
	}

	fragment ContactsFragment on PrismicContactsPage {
		data {
			main_email
			main_subheading
			phone_formatted
			country_specific_phones {
				country_code
				geo_phone_formatted
			}
			emails {
				address
				label
			}
			social_links {
				address
				label
			}
		}
	}
`
