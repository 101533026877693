import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { CtaButton } from 'components/design'

export default function ContactForm() {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionError, setSubmissionError] = useState(false)
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm({
		defaultValues: { name: '', email: '', message: '' },
	})

	const rules = {
		name: {
			required: 'Please introduce yourself',
			minLength: {
				value: 3,
				message: 'Name cannot be shorter than 3 characters',
			},
		},
		email: {
			required: 'The email is required',
			pattern: {
				value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				message: 'Please provide a valid email address',
			},
		},

		message: {
			required: 'The message is required',
		},
	}

	const submitInlineForm = (values) => {
		// const endpoint = 'http://localhost:2008/contact-form'
		const endpoint =
			'https://europe-west3-cuub-website.cloudfunctions.net/zCrmConnector/contact-form'

		setIsSubmitting(true)

		fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		})
			.then((response) => {
				if (response.ok) {
					navigate('/message-sent/')
				} else {
					setIsSubmitting(false)
					setSubmissionError(true)
				}
			})
			.catch((error) => {
				console.error('Error:', error)
				setIsSubmitting(false)
				setSubmissionError(true)
			})
	}

	return (
		<form onSubmit={handleSubmit(submitInlineForm)}>
			<div className="relative">
				<input
					className="w-full border-0 border-b border-black/25 bg-transparent px-0 py-4 focus:outline-none focus:ring-black focus:ring-opacity-0"
					type="text"
					placeholder="Your name"
					{...register('name', rules.name)}
				/>
				<p className="absolute bottom-2 right-0 text-sm italic text-accent">
					{errors?.name ? errors.name.message : ''}
				</p>
			</div>

			<div className="relative mt-6">
				<input
					className="w-full border-0 border-b border-black/25 bg-transparent px-0 py-4 focus:outline-none focus:ring-black focus:ring-opacity-0"
					type="email"
					placeholder="Your email"
					{...register('email', rules.email)}
				/>
				<p className="absolute bottom-2 right-0 text-sm italic text-accent">
					{errors?.email ? errors.email.message : ''}
				</p>
			</div>

			<div className="relative mt-6">
				<textarea
					className="w-full resize-none border-0 border-b border-black/25 bg-transparent px-0 py-4 focus:outline-none focus:ring-black focus:ring-opacity-0"
					rows="6"
					placeholder="Your message"
					{...register('message', rules.message)}
				/>
				<p className="absolute top-3 right-0 text-sm italic text-accent">
					{errors?.message ? errors.message.message : ''}
				</p>
			</div>

			{/*<button
				className={`cta-black cta-black-wide relative mt-10 flex w-3/5 items-center bg-black px-4 py-5 text-xs font-semibold uppercase leading-6 tracking-px text-white disabled:cursor-not-allowed disabled:bg-opacity-50 xl:w-1/2 xl:items-baseline xl:px-6 xl:text-sm xl:leading-6`}
				type="submit"
				disabled={isSubmitting || Object.keys(errors).length > 0}
			>
				Send message
			</button>*/}
			<CtaButton
				type="submit"
				disabled={isSubmitting || Object.keys(errors).length > 0}
				label="Send message"
				className="mt-10 !w-screen-step-7"
			/>

			<p
				className={`mt-8 text-lg font-bold text-accent ${
					submissionError ? 'block' : 'hidden'
				}`}
			>
				Sorry for the technical difficulties. Please contact us via
				hello@cuubstudio.com.
			</p>
		</form>
	)
}
