import React from 'react'
import { Link } from 'gatsby'
import Cuub from 'svg/cuub'

const CuubWorkmark = () => (
	<Link to="/" className={`accent-hover`}>
		<Cuub />
	</Link>
)

export default CuubWorkmark
